<app-countdown-timer (countdownEnded)="onCountdownEnd($event)" class="d-none"></app-countdown-timer>

<section class="workspaces-banner document-handling-launch">
    <app-product-left-text-with-button [imgSrc] = "tallydmsplugin_img" [contentH]="tallypluginHeroText"></app-product-left-text-with-button >
</section>

<section class="problem-sec">
    <div class="container productLeft">
        <div class="">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto p-0">
            <div class=" d-flex align-items-start justify-content-around resp-container">
    
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 p-0 plAlign leftpanel">
                <div class="workspace-banner-content">
                  <h6 class="heading-6 text-uppercase">What We Offer</h6>
                  <h1 class="heading-1">Two Solutions. One Plugin. Endless Value.</h1>
                  
                  <p class="common-para">Why juggle multiple tools when dox2U gives you everything you need?</p>
    
                </div>
              </div>
              <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 justify-content-center p-0">
                <div class="d-flex flex-column problem-rightpanel">
                    <p class="small-heading">Secure Automated Tally Backups</p>
                    <p class="common-para mb-0">Secure Automated Tally Backups that protect your business against data loss, accidental deletions, hacks or ransomware!</p>

                </div>
                <div class="d-flex flex-column problem-rightpanel">
                    <p class="small-heading">Smart Document Management</p>
                    <p class="common-para mb-0">Smart Document Management that integrates seamlessly with Tally to organize, access, and control documents from within Tally itself.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</section>

    <section id="downloadForm" class="download-and-install top-padd bottom-padd ">
        <div class="container">
            <div class="">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto dms-header-container">
                    <p class="dms-header1">DOWNLOAD PLUGIN</p>
                    <h3 class="size-fourty mb-3">Get dox2U’s Tally Plugin Now!</h3>
                    <p class="common-heading" style="text-align: center;">Getting started with dox2U’s Tally Plugin is a simple two-step process. 
                        You can DIY or <a href="https://calendly.com/dox2u-dms/tally-plugin-installation" target="_blank" class="schedule-call"> schedule a call</a> with one of our experts.</p>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <div class="d-flex justify-content-between align-items-start flex-wrap" style="-gap: 50px;">
                        <div
                            class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 d-flex flex-column justify-content-start t-cen centText">
    
                            <h3 class="dmsForm-step1">Step 1</h3>
                            <h5 class="dmsForm-h5">Create a dox2U workspace</h5>
                            <p class="para-common">A <span>dox2U workspace</span> is a private secured space on dox2U’s cloud where all your documents are organized and stored for your team’s access only.</p>
                            <div>
                                <div class="d-flex ">
                                    
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span> 15 days Free Trial</p>
                                </div>
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"><span class="horizontal-line"> </span>No credit card needed</p>
                                </div>
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"><span class="horizontal-line"> </span>Multiple plans to choose from</p>
                                </div>
                                
                            </div>
                            <div class="getStarted-btn">
                                <button class="button-primary my-2 my-sm-0 cursor-pointer gs-link" (click)="openCreateWS()" >
                                    Get Started
                                  </button>
                            </div>
                        </div>
                        <div class="tallyBackupForm col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-center artopD">
                            <h3 class="dmsForm-step1">Step 2</h3>
                            <h5 class="dmsForm-h5">Download Tally plugin</h5>
                            <p class="para-common">Our Plugin supports Tally ERP 9, Tally Prime and Tally on Cloud</p>
                            <div id="tallyDMSshow" *ngIf="!submittedForm">
                                <form [formGroup]="dmsForm" (ngSubmit)="onSubmit()">
                                    <div class="d-flex flex-column colmG">
                                        <div class="d-flex form-container w-100">
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">First Name<span class="text-danger"
                                                            >*</span>
                                                        </p>
                                                    </label>
                                                    <input class="form-control" formControlName="firstname" maxlength="100" id="firstname" autocomplete="off" name="firstname" placeholder="Enter first name" (keypress)="resetFirstNameDivTP($event)">
                                                    
                                                        <!-- <div *ngIf="dmsForm.get('firstname')?.hasError('required') && dmsForm.get('firstname').touched" class="error">This field cannot be empty</div> -->
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="firstNameValidDivTP">
                                                    This field cannot be empty
                                                  </p>
                                                  
                                                <p id="errfirstname1" class="mb-0  meserr text-danger"></p>
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Last Name<span class="text-danger"
                                                                >*</span></p>
                                                    </label>
                                                    <input class="form-control" formControlName="lastname" maxlength="100" autocomplete="off" id="lastname" placeholder="Enter last name" name="lastname" (keypress)="resetLastNameDivTP($event)">
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="lastNameValidDivTP">
                                                    This field cannot be empty
                                                  </p>
                                                <p id="errlastname1" class="mb-0  meserr"></p>
                                            </div>
                                        </div>
                                        <!-- <div class="d-flex form-container w-100"> -->
                                            <div class="d-flex form-container w-100">
                                                <div class="d-flex flex-column w-50 input-container">
                                                    <div class="nameInput d-flex flex-column form-group">
                                                        <label>
                                                            <p class="label-field mb-1 tunset">Email Address<span
                                                                    style="color:red">*</span>
                                                            </p>
                                                        </label>
                                                        <input class="form-control" autocomplete="off" formControlName="email" id="tallyemail" placeholder="Enter your email address" name="tallyemail" (keypress)="resetEmailDivTP($event)">
                                                    </div>
                                                    <p class="mb-0 meserr text-danger" *ngIf="emailValidDivEmptyTP">
                                                        This field cannot be empty
                                                      </p>
                                                      <p class="mb-0 meserr text-danger" *ngIf="emailValidDivInvalidTP">
                                                        Please enter the valid email
                                                      </p>
                                                      
                                                   
                                                </div>
                                                <div class="d-flex flex-column w-50 input-container">
                                                    <div class="nameInput d-flex flex-column form-group">
                                                        <label class="phn-label">
                                                            <p class="label-field mb-0 tunset">Phone Number<span class="text-danger"
                                                                >*</span></p>
                                                        </label>
        
                                                        <!-- <input name="phone" type="tel" id="phoneC" 
                                                            class="getting-started-input phn-inp inp100 form-control" placeholder="0987654321" formControlName="phone" (input)="resetPhoneDivTP($event)"
                                                            
                                                            maxlength="17"> -->
        
                                                            <ngx-intl-tel-input class="getting-started-input phn-inp inp100 form-control" (input)="resetPhoneDivTP($event)"
                                                            [cssClass]="'custom'"
                                                          
                                                            [enableAutoCountrySelect]="true"
                                                            [enablePlaceholder]="true"
                                                            [enableAutoCountrySelect]="true"
                                                            [searchCountryFlag]="true"
                                                            [searchCountryField]="[
                                                              SearchCountryField.Iso2,
                                                              SearchCountryField.Name
                                                            ]"
                                                            [selectFirstCountry]="false"
                                                            [selectedCountryISO]="CountryISO.India"
                                                            [maxLength]="15"
                                                            [phoneValidation]="true"
                                                            [separateDialCode]="separateDialCode"
                                                            [numberFormat]="PhoneNumberFormat.National"
                                                            name="phone"
                                                            formControlName="phone"
                                                            (countryChange)="onCountryChangeTP($event)"
        ></ngx-intl-tel-input>
                                                    </div>
                                                    <p class="mb-0 meserr text-danger" *ngIf="phoneValidDivTP">
                                                        This field cannot be empty
                                                      </p>
        
                                                      <p class="mb-0 meserr text-danger" *ngIf="phoneValidationCheckDivTP">
                                                        Please enter a valid phone number
                                                      </p>
        
                                                </div>
                                            </div>
                                           
    
                                            
                                        <p class="mb-1 comm-12">All input fields are mandatory <span class="text-danger">*</span></p>
                                        <div class="justify-content-center align-items-center d-flex submit-button">
                                            <button class="button-primary w-100 trydox" id="myButton" type="submit"><span
                                                    class="hideDownload">Download</span> </button>
                                            <div id="loader" class="spinner-border text-white"
                                                style="display:none;position:absolute;" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="tallyDMShide" *ngIf="submittedForm" class="d-flex  flex-column justify-content-center align-items-center"
                                >
                                
                                <img src="../../../assets/Image/tallysuccess.svg" style="margin-bottom: 32px;">
                                <p class="common-para text-center">Thank you! We’ve sent you an email with a link for downloading our Tally Plugin</p>
                            </div>
    
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </section>

   
    <section class="problem-sec" id="tallyBackupSection" style="background-color: #F7F9FC;">
        <div class="container productLeft">
            <div class="">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class=" d-flex align-items-start justify-content-between resp-container">
        
                  <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 p-0 plAlign leftpanel">
                    <div class="workspace-banner-content textAlign">
                      <h6 class="heading-6 text-uppercase">Automated Tally Backups</h6>
                      <h1 class="heading-1">Is Your Tally Data Safe? Think Again! </h1>
                      
                      <img src="../../../assets/Image/tallySafe.svg" class="img-fluid">
        
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 justify-content-center p-0">
                    <p class="heading-6">Tally is crucial for your business. Don’t let Tally data losses due to hard drive failures, accidental deletions or hacks derail your entire business. With dox2U’s Tally Backup Solution, your data is:</p>
                    <div class="d-flex flex-column problem-rightpanel">
                        <p class="small-heading">Automatically Protected</p>
                        <p class="common-para mb-0">Set flexible daily, weekly, or monthly schedules for automatic backups</p>
    
                    </div>
                    <div class="d-flex flex-column problem-rightpanel">
                        <p class="small-heading">Secure and Encrypted</p>
                        <p class="common-para mb-0">Backups are scanned for viruses and securely stored offsite</p>
    
                    </div>
                    <div class="d-flex flex-column problem-rightpanel">
                        <p class="small-heading">Efficiently Managed</p>
                        <p class="common-para mb-0">BackupsBackups are compressed for optimal storage with intelligent cleanup ensuring you never run out of space are scanned for viruses and securely stored offsite</p>
    
                    </div>
                    <a routerLink="/tallybackup" target="_blank"> <button class="button-primary mt-5">Learn more <img src="../../../assets/Image/rightArrow_white.svg" style="margin-left: 13px;"></button></a>   
                  </div>
                </div>
              </div>
            </div>
          </div>
    </section>
    <section class="dox-competition" style="background-color: #ffffff;" id="dox-competition">
        <div class="container special containerOverwrite">
            <div class=" w-100">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto mb72">
                    <div class="workspace-banner-content text-center">
                        <h6 class="heading-6 text-uppercase">Document Management in Tally</h6>
                        <h1 class="heading-1">Turbocharge Your Tally With Document Management </h1>
                        
                        <p class="common-para mb-1">Link, control, and access your documents seamlessly right from within your Tally transactions. Work smarter with dox2U’s Document Management System (DMS) for Tally:</p>         
                      </div>
    
            
        </div>
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
            <div class=" d-flex align-items-start justify-content-between resp-container">
    
              <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0 plAlign leftpanel">
                <div class="workspace-banner-content">
                    <div class="d-flex mb-5 marginResp">
                        <img class="img-height img-mb" src="../../../assets/Image/seamless_integration.svg">
                        <div class="d-flex flex-column ml-3">
                            <p class="mb-0 values-offered-h1">Seamless Integration:</p>
                            <p class="values-offered-h2">Attach documents directly to vouchers and masters</p>
                        </div>
                    </div>
                    <div class="d-flex mb-5 marginResp">
                        <img class="img-height img-mb" src="../../../assets/Image/anytime_aa.svg">
                        <div class="d-flex flex-column ml-3">
                            <p class="mb-0 values-offered-h1">Anytime, Anywhere Access:</p>
                            <p class="values-offered-h2">Access documents from within Tally or directly from dox2U</p>
                        </div>
                    </div>
                    <div class="d-flex mb-5 marginResp">
                        <img class="img-height img-mb" src="../../../assets/Image/secure_collab.svg">
                        <div class="d-flex flex-column ml-3">
                            <p class="mb-0 values-offered-h1">Secure Collaboration:</p>
                            <p class="values-offered-h2">Share documents securely with team members or guests via WhatsApp or email</p>
                        </div>
                    </div>
                    <a routerLink="/tallydms" target="_blank"> <button *ngIf="isLaunchTime" class="button-primary" style="margin-top: 6px;">Learn more <img src="../../../assets/Image/rightArrow_white.svg" style="margin-left: 13px;"></button></a>   
                </div>
              </div>
              <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end p-0 turbo-img">
                <img src="../../../assets/Image/turbocharge.svg" class="img-fluid">
              </div>
            </div>
          </div>
        </div>
        </div>
    </section>
    <section id="secureData" class="secure-data top-padd bottom-padd common-padd overflow-hidden">
        <div class="container">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <div class="knox-data">
                        <h6 class="font-comm text-uppercase">Fort Knox For Your Data</h6>
                        <h3 class="size-fourty mb-3">Your Data’s Safety is Our Top Priority</h3>
                        <p class="common-heading share-info text-center">Every backup and document stored with dox2U is secured using:</p>
                        </div>
                        <div class="row col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto d-flex align-items-center justify-content-between">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 p-0">

                            <div class="">
                                <p class="common-para wunset mb-0"> <span class="horizontal-line"> </span> Advanced Encryption</p>
                                <p class="common-para text1">Protecting your data in transit and at rest</p>
                            </div>
                            <div class="">
                                <p class="common-para wunset mb-0"> <span class="horizontal-line"> </span> Robust Cloud Vault:</p>
                                <p class="common-para text1">Hosted on Microsoft Azure, compliant with SOC II standards</p>
                            </div>
                            <div class="">
                                <p class="common-para wunset mb-0"> <span class="horizontal-line"> </span> Access Controls:</p>
                                <p class="common-para text1">Ensure only authorized users manage your Tally backups and documents</p>
                            </div>
                                 
                        </div>

                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: center;">
                            <img src="../../../assets/Image/secureData.svg" class="img-fluid">

                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </section>

    <section class="turbo-learn">
        <div class="container special containerOverwrite p-0">
            <div class=" w-100">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto p-0">
                    <div class="workspace-banner-content text-center">
                        <!-- <h6 class="heading-6 text-uppercase">Tally Plugin for Document Management </h6> -->
                        <h1 class="heading-1">Ready to Unlock the Full Power of Tally?</h1>
                        
                        <p class="common-para mb-0">rotect your data with automated Backups. Achieve much more with document</p>         
                        <p class="common-para mb-0"> management within Tally. Start with dox2U today.</p>      
                        <a (click)="scrollToDownloadPlugin()"> <button class="button-transparent bg-white">Get Started Now<img src="../../../assets/Image/rightArrow_blue.svg" style="margin-left: 13px;"></button></a>   
                      </div>
                    </div>
                </div>
                </div>
                
    </section>