<section id="tally-document" class="physical-cabinets top-padd  common-padd  bg-tally overflow-hidden" *ngIf="!isCountdownFinished">
  <div class="container">
      <div class="d-flex align-items-center justify-content-center">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="solutions-fall-fat-content  tallyDocument-margin mx-auto">
                  <h6 class="heading-6">Exciting Updates!</h6>
                  <h2 class="heading-adon heading-3">Discover Our Latest Features and Enhancements
                  </h2>
              </div>

               
              <div>
                  <owl-carousel-o [options]="customOptions" id="firstCarousel">

                    <ng-template carouselSlide>
                      <div class="slide">
                          <div class="smart-cabinet-card electronic-cabinet-card"> 
                              <div>
                                <img src="../../../assets/Image/upload_file.svg" alt="Electronic Cabinet" class="img-fluid">
                              
                              <h4 class="heading-4">QuickLink</h4>
                                <p class="common-para">Effortlessly upload documents through the dox2U web-app or Sync-App for quick linking with Tally Vouchers/Masters. Access and link in a snap!</p>
                             </div>
                       
                            </div>
                      </div>
                    </ng-template>
                 
                    <ng-template carouselSlide>
                      <div class="slide">
                          <div class="smart-cabinet-card easy-navigation-card">
                              <div>
                                <img src="../../../assets/Image/whatsapp_iconi.svg" alt="Easy navigation" class="img-fluid">
                                <h4 class="heading-4">WhatsApp Sharing</h4>
                                <p class="common-para">Secure one-click document sharing with guests over WhatsApp, ensuring seamless collaboration with confidence and privacy.</p>
                              </div>
                            </div>
                      </div>
                    </ng-template>
                    <ng-template carouselSlide>
                      <div class="slide">
                          <div class="smart-cabinet-card pinpoint-documents-card">
                              <div>
                                <img src="../../../assets/Image/newspaper.svg" alt="Pinpoint documents" class="img-fluid">
                                <h4 class="heading-4">Digital Vouchers</h4>
                                <p class="common-para">Automated uploading to cloud of Tally vouchers for digital archiving, ensuring organized and accessible financial records at your fingertips.</p>
                              </div>
                            </div>
                      </div>
                    </ng-template>
                    <ng-template carouselSlide>
                      <div class="slide">
                          <div class="smart-cabinet-card easy-navigation-card"> 
                              <div>
                                <img src="../../../assets/Image/voucher.svg" alt="Electronic Cabinet" class="img-fluid">
                              
                              <h4 class="heading-4">Auto Voucher Upload</h4>
                                <p class="common-para">Automated upload of your vouchers (Sales Invoice or Credit Notes or Debit Notes) to dox2U, as and when you create them in Tally.</p>
                             </div>
                       
                            </div>
                      </div>
                    </ng-template>

                    <ng-template carouselSlide>
                      <div class="slide">
                          <div class="smart-cabinet-card electronic-cabinet-card"> 
                              <div>
                                <img src="../../../assets/Image/migrate_DMS.svg" alt="Electronic Cabinet" class="img-fluid">
                              
                              <h4 class="heading-4">Migrate DMS</h4>
                                <p class="common-para">Migrate from a local DMS Tally add-on to dox2U’s secure cloud based solution with a single click and work better.</p>
                             </div>
                       
                            </div>
                      </div>
                    </ng-template>
                   
                  </owl-carousel-o>
                  <owl-carousel-o [options]="customOptionssec" id="secCarousel">

                      <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card electronic-cabinet-card"> 
                                <div>
                                  <img src="../../../assets/Image/upload_file.svg" alt="Electronic Cabinet" class="img-fluid">
                                
                                <h4 class="heading-4">QuickLink</h4>
                                  <p class="common-para">Effortlessly upload documents through the dox2U web-app or Sync-App for quick linking with Tally Vouchers/Masters. Access and link in a snap!</p>
                               </div>
                         
                              </div>
                        </div>
                      </ng-template>
                   
                      <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card easy-navigation-card">
                                <div>
                                  <img src="../../../assets/Image/whatsapp_iconi.svg" alt="Easy navigation" class="img-fluid">
                                  <h4 class="heading-4">WhatsApp Sharing</h4>
                                  <p class="common-para">Secure one-click document sharing with guests over WhatsApp, ensuring seamless collaboration with confidence and privacy.</p>
                                </div>
                              </div>
                        </div>
                      </ng-template>
                      <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card pinpoint-documents-card">
                                <div>
                                  <img src="../../../assets/Image/newspaper.svg" alt="Pinpoint documents" class="img-fluid">
                                  <h4 class="heading-4">Digital Vouchers</h4>
                                  <p class="common-para">Automated uploading to cloud of Tally vouchers for digital archiving, ensuring organized and accessible financial records at your fingertips.</p>
                                </div>
                              </div>
                        </div>
                      </ng-template>
                      <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card easy-navigation-card"> 
                                <div>
                                  <img src="../../../assets/Image/voucher.svg" alt="Electronic Cabinet" class="img-fluid">
                                
                                <h4 class="heading-4">Auto Voucher Upload</h4>
                                  <p class="common-para">Automated upload of your vouchers (Sales Invoice or Credit Notes or Debit Notes) to dox2U, as and when you create them in Tally.</p>
                               </div>
                         
                              </div>
                        </div>
                      </ng-template>

                      <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card electronic-cabinet-card"> 
                                <div>
                                  <img src="../../../assets/Image/migrate_DMS.svg" alt="Electronic Cabinet" class="img-fluid">
                                
                                <h4 class="heading-4">Migrate DMS</h4>
                                  <p class="common-para">Migrate from a local DMS Tally add-on to dox2U’s secure cloud based solution with a single click and work better.</p>
                               </div>
                         
                              </div>
                        </div>
                      </ng-template>
                     
                    </owl-carousel-o>
                
                </div>
           
          </div>
      </div>
      <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto cards">

          <div class="row d-flex align-items-center justify-content-center cards-container mx-auto">
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 card-container1">
                <div class="smart-cabinet-card electronic-cabinet-card"> 
                  <div>
                    <img src="../../../assets/Image/upload_file.svg" alt="Electronic Cabinet" class="img-fluid">
                  
                  <h4 class="heading-4">QuickLink</h4>
                    <p class="common-para">Effortlessly upload documents through the dox2U web-app or Sync-App for quick linking with Tally Vouchers/Masters. Access and link in a snap!</p>
                 </div>
           
                </div>
              </div>
        
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 card-container2">
                <div class="smart-cabinet-card easy-navigation-card">
                  <div>
                    <img src="../../../assets/Image/whatsapp_iconi.svg" alt="Easy navigation" class="img-fluid">
                    <h4 class="heading-4">WhatsApp Sharing</h4>
                    <p class="common-para">Secure one-click document sharing with guests over WhatsApp, ensuring seamless collaboration with confidence and privacy.</p>
                  </div>
                </div>
              </div>
        
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 card-container3">
                <div class="smart-cabinet-card pinpoint-documents-card">
                  <div>
                    <img src="../../../assets/Image/newspaper.svg" alt="Pinpoint documents" class="img-fluid">
                    <h4 class="heading-4">Digital Vouchers</h4>
                    <p class="common-para">Automated uploading to cloud of Tally vouchers for digital archiving, ensuring organized and accessible financial records at your fingertips.</p>
                  </div>
                </div>
              </div>
            </div>
        <div class="row d-flex align-items-center  " style="min-height:280px">


              <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center ">
                  <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                      aria-orientation="vertical">
                      <div class="featuredBtn cursor-pointer" id="v-pills-home-tab" id="btnOne"
                           role="tab" (click)="callSecureData()" [class.active]="showSecureCloudData"
                          >Secure Cloud Solution</div>
                      <div class=" featuredBtn robust-button mt-3 text-center cursor-pointer" id="v-pills-profile-tab" id="two"
                          role="tab" (click)="callRobustData()" [class.active]="showRobustDocsData"
                       >Robust Document
                          Management System</div>
                  </div>
              </div>

              <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
                  <div class="retrieval-as-easy">
                      <div class="">
                          <div class="" role="button" *ngIf="showSecureCloudData">
                              <div>
                                  <div class="d-flex textleft">
                                      <li></li>
                                      <p class="common-para-adone tally-align">Securely store all your documents in a
                                          specialized
                                          cloud-based Document Management System</p>
                                  </div>
                                  <div class="d-flex textleft">
                                      <li></li>
                                      <p class="common-para-adone tally-align">Reduce the risk of data loss due to
                                          hardware failures
                                          or computer crashes</p>
                                  </div>
                                  <div class="d-flex textleft">
                                      <li></li>
                                      <p class="common-para-adone tally-align">Enjoy automatic backups and data
                                          redundancy provided
                                          by cloud storage</p>
                                  </div>
                                  <div class="d-flex textleft">
                                      <li></li>
                                      <p class="common-para-adone tally-align">Benefit from scalable storage options,
                                          accommodating
                                          your growing document needs without worrying about physical storage
                                          limitations.</p>
                                  </div>

                              </div>
                          </div>
                          <div class="" role="button" *ngIf="showRobustDocsData">
                              <div class="d-flex textleft">
                                  <li></li>
                                  <p class="common-para-adone tally-align">Access your documents from any device with an internet connection</p>
                              </div>
                              <div class="d-flex textleft">
                                  <li></li>
                                  <p class="common-para-adone tally-align">Collaborate and share documents seamlessly with team members or clients, regardless of their location.</p>
                              </div>
                              <div class="d-flex textleft">
                                  <li></li>
                                  <p class="common-para-adone tally-align">Documents are always displayed on a browser, securing your information at all times</p>
                              </div>
                              <div class="d-flex textleft">
                                  <li></li>
                                  <p class="common-para-adone tally-align">Advanced access controls for deciding who can access your documents
                                  </p>
                              </div>

                          </div>
                      </div>


                  </div>

              </div>
          </div> 

          

      </div> -->
  
  
  
  </div>


</section>


<section id="tally-document" class="physical-cabinets top-padd  common-padd  bg-tally overflow-hidden" *ngIf="isCountdownFinished">
    <div class="container">
        <div class="d-flex align-items-center justify-content-center">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="solutions-fall-fat-content  tallyDocument-margin mx-auto">
                    <h6 class="heading-6">Exciting Updates!</h6>
                    <h2 class="heading-adon heading-3">Discover Our Latest Features and Enhancements
                    </h2>
                </div>

                 
                <div>
                    <owl-carousel-o [options]="customOptions" id="firstCarousel">

                      <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card"> 
                                <div>
                                  <img src="../../../assets/Image/upload_file.svg" alt="Electronic Cabinet" class="img-fluid">
                                
                                <h4 class="heading-4">Advanced Controls</h4>
                                  <p class="common-para">Keep your processes streamlined with dox2U’s advanced controls like enforcing document attachments in Voucher / Masters as per your needs</p>
                               </div>
                         
                              </div>
                        </div>
                      </ng-template>
                   
                      <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card easy-navigation-card">
                                <div>
                                  <img src="../../../assets/Image/whatsapp_iconi.svg" alt="Easy navigation" class="img-fluid">
                                  <h4 class="heading-4">WhatsApp Sharing</h4>
                                  <p class="common-para">Secure one-click document sharing with guests over WhatsApp, ensuring seamless collaboration with confidence and privacy.</p>
                                </div>
                              </div>
                        </div>
                      </ng-template>
                      <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card">
                                <div>
                                  <img src="../../../assets/Image/tallybackup_carousel.svg" alt="Easy navigation" class="img-fluid">
                                  <h4 class="heading-4">Tally Backup</h4>
                                  <p class="common-para">dox2U’s Automated Tally Backup lets you securely backup your Tally data, helping you maintain business continuity in the event of a data loss!</p>
                                  <a routerLink="/tallybackup" class="d-flex align-items-center mr-2" style="color: #585858;">Learn More <img src="../../../assets/Image/black_arrow.svg" class="mb-0 ml-2" style="width: 12px; height: 12px;"></a>
                                </div>
                              </div>
                        </div>
                      </ng-template>
                      <!-- <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card easy-navigation-card">
                                <div>
                                  <img src="../../../assets/Image/newspaper.svg" alt="Pinpoint documents" class="img-fluid">
                                  <h4 class="heading-4">Digital Vouchers</h4>
                                  <p class="common-para">Automated uploading to cloud of Tally vouchers for digital archiving, ensuring organized and accessible financial records at your fingertips.</p>
                                </div>
                              </div>
                        </div>
                      </ng-template>
                      <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card"> 
                                <div>
                                  <img src="../../../assets/Image/voucher.svg" alt="Electronic Cabinet" class="img-fluid">
                                
                                <h4 class="heading-4">Auto Voucher Upload</h4>
                                  <p class="common-para">Automated upload of your vouchers (Sales Invoice or Credit Notes or Debit Notes) to dox2U, as and when you create them in Tally.</p>
                               </div>
                         
                              </div>
                        </div>
                      </ng-template>

                      <ng-template carouselSlide>
                        <div class="slide">
                            <div class="smart-cabinet-card easy-navigation-card"> 
                                <div>
                                  <img src="../../../assets/Image/migrate_DMS.svg" alt="Electronic Cabinet" class="img-fluid">
                                
                                <h4 class="heading-4">Migrate DMS</h4>
                                  <p class="common-para">Migrate from a local DMS Tally add-on to dox2U’s secure cloud based solution with a single click and work better.</p>
                               </div>
                         
                              </div>
                        </div>
                      </ng-template> -->
                     
                    </owl-carousel-o>
                    <owl-carousel-o [options]="customOptionssec" id="secCarousel">

                        <ng-template carouselSlide>
                          <div class="slide">
                              <div class="smart-cabinet-card electronic-cabinet-card"> 
                                  <div>
                                    <img src="../../../assets/Image/upload_file.svg" alt="Electronic Cabinet" class="img-fluid">
                                  
                                  <h4 class="heading-4">Advanced Controls</h4>
                                    <p class="common-para">Keep your processes streamlined with dox2U’s advanced controls like enforcing document attachments in Voucher / Masters as per your needs</p>
                                 </div>
                           
                                </div>
                          </div>
                        </ng-template>
                     
                        <ng-template carouselSlide>
                          <div class="slide">
                              <div class="smart-cabinet-card easy-navigation-card">
                                  <div>
                                    <img src="../../../assets/Image/whatsapp_iconi.svg" alt="Easy navigation" class="img-fluid">
                                    <h4 class="heading-4">WhatsApp Sharing</h4>
                                    <p class="common-para">Secure one-click document sharing with guests over WhatsApp, ensuring seamless collaboration with confidence and privacy.</p>
                                  </div>
                                </div>
                          </div>
                        </ng-template>
                        <ng-template carouselSlide>
                          <div class="slide">
                              <div class="smart-cabinet-card">
                                  <div>
                                    <img src="../../../assets/Image/tallybackup_carousel.svg" alt="Easy navigation" class="img-fluid">
                                    <h4 class="heading-4">Tally Backup</h4>
                                    <p class="common-para">dox2U’s Automated Tally Backup lets you securely backup your Tally data, helping you maintain business continuity in the event of a data loss!</p>
                                    <a routerLink="/tallybackup" class="d-flex align-items-center mr-2" style="color: #585858;">Learn More <img src="../../../assets/Image/black_arrow.svg" class="mb-0 ml-2" style="width: 12px; height: 12px;"></a>
                                  </div>
                                </div>
                          </div>
                        </ng-template>
                        <!-- <ng-template carouselSlide>
                          <div class="slide">
                              <div class="smart-cabinet-card pinpoint-documents-card">
                                  <div>
                                    <img src="../../../assets/Image/newspaper.svg" alt="Pinpoint documents" class="img-fluid">
                                    <h4 class="heading-4">Digital Vouchers</h4>
                                    <p class="common-para">Automated uploading to cloud of Tally vouchers for digital archiving, ensuring organized and accessible financial records at your fingertips.</p>
                                  </div>
                                </div>
                          </div>
                        </ng-template>
                        <ng-template carouselSlide>
                          <div class="slide">
                              <div class="smart-cabinet-card easy-navigation-card"> 
                                  <div>
                                    <img src="../../../assets/Image/voucher.svg" alt="Electronic Cabinet" class="img-fluid">
                                  
                                  <h4 class="heading-4">Auto Voucher Upload</h4>
                                    <p class="common-para">Automated upload of your vouchers (Sales Invoice or Credit Notes or Debit Notes) to dox2U, as and when you create them in Tally.</p>
                                 </div>
                           
                                </div>
                          </div>
                        </ng-template>
  
                        <ng-template carouselSlide>
                          <div class="slide">
                              <div class="smart-cabinet-card electronic-cabinet-card"> 
                                  <div>
                                    <img src="../../../assets/Image/migrate_DMS.svg" alt="Electronic Cabinet" class="img-fluid">
                                  
                                  <h4 class="heading-4">Migrate DMS</h4>
                                    <p class="common-para">Migrate from a local DMS Tally add-on to dox2U’s secure cloud based solution with a single click and work better.</p>
                                 </div>
                           
                                </div>
                          </div>
                        </ng-template> -->
                       
                      </owl-carousel-o>
                  
                  </div>
             
            </div>
        </div>
        <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto cards">

            <div class="row d-flex align-items-center justify-content-center cards-container mx-auto">
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 card-container1">
                  <div class="smart-cabinet-card electronic-cabinet-card"> 
                    <div>
                      <img src="../../../assets/Image/upload_file.svg" alt="Electronic Cabinet" class="img-fluid">
                    
                    <h4 class="heading-4">QuickLink</h4>
                      <p class="common-para">Effortlessly upload documents through the dox2U web-app or Sync-App for quick linking with Tally Vouchers/Masters. Access and link in a snap!</p>
                   </div>
             
                  </div>
                </div>
          
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 card-container2">
                  <div class="smart-cabinet-card easy-navigation-card">
                    <div>
                      <img src="../../../assets/Image/whatsapp_iconi.svg" alt="Easy navigation" class="img-fluid">
                      <h4 class="heading-4">WhatsApp Sharing</h4>
                      <p class="common-para">Secure one-click document sharing with guests over WhatsApp, ensuring seamless collaboration with confidence and privacy.</p>
                    </div>
                  </div>
                </div>
          
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 card-container3">
                  <div class="smart-cabinet-card pinpoint-documents-card">
                    <div>
                      <img src="../../../assets/Image/newspaper.svg" alt="Pinpoint documents" class="img-fluid">
                      <h4 class="heading-4">Digital Vouchers</h4>
                      <p class="common-para">Automated uploading to cloud of Tally vouchers for digital archiving, ensuring organized and accessible financial records at your fingertips.</p>
                    </div>
                  </div>
                </div>
              </div>
          <div class="row d-flex align-items-center  " style="min-height:280px">


                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center ">
                    <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                        aria-orientation="vertical">
                        <div class="featuredBtn cursor-pointer" id="v-pills-home-tab" id="btnOne"
                             role="tab" (click)="callSecureData()" [class.active]="showSecureCloudData"
                            >Secure Cloud Solution</div>
                        <div class=" featuredBtn robust-button mt-3 text-center cursor-pointer" id="v-pills-profile-tab" id="two"
                            role="tab" (click)="callRobustData()" [class.active]="showRobustDocsData"
                         >Robust Document
                            Management System</div>
                    </div>
                </div>

                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start ">
                    <div class="retrieval-as-easy">
                        <div class="">
                            <div class="" role="button" *ngIf="showSecureCloudData">
                                <div>
                                    <div class="d-flex textleft">
                                        <li></li>
                                        <p class="common-para-adone tally-align">Securely store all your documents in a
                                            specialized
                                            cloud-based Document Management System</p>
                                    </div>
                                    <div class="d-flex textleft">
                                        <li></li>
                                        <p class="common-para-adone tally-align">Reduce the risk of data loss due to
                                            hardware failures
                                            or computer crashes</p>
                                    </div>
                                    <div class="d-flex textleft">
                                        <li></li>
                                        <p class="common-para-adone tally-align">Enjoy automatic backups and data
                                            redundancy provided
                                            by cloud storage</p>
                                    </div>
                                    <div class="d-flex textleft">
                                        <li></li>
                                        <p class="common-para-adone tally-align">Benefit from scalable storage options,
                                            accommodating
                                            your growing document needs without worrying about physical storage
                                            limitations.</p>
                                    </div>

                                </div>
                            </div>
                            <div class="" role="button" *ngIf="showRobustDocsData">
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para-adone tally-align">Access your documents from any device with an internet connection</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para-adone tally-align">Collaborate and share documents seamlessly with team members or clients, regardless of their location.</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para-adone tally-align">Documents are always displayed on a browser, securing your information at all times</p>
                                </div>
                                <div class="d-flex textleft">
                                    <li></li>
                                    <p class="common-para-adone tally-align">Advanced access controls for deciding who can access your documents
                                    </p>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div> 

            

        </div> -->
    
    
    
    </div>
 

</section>
<app-countdown-timer class="d-none" (countdownEnded)="onCountdownEnd($event)"></app-countdown-timer>