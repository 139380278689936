<app-countdown-timer (countdownEnded)="onCountdownEnd($event)" *ngIf="!isLaunchTime"></app-countdown-timer>
<section class="workspaces-banner document-handling-banner" *ngIf="!isLaunchTime">
    <app-product-left-text-with-button [imgSrc] = "tallybackup_img" [contentH]="tallybackupHeroText"></app-product-left-text-with-button >
</section>

<section class="download-and-install top-padd bottom-padd common-padd-tallyForm " *ngIf="!isLaunchTime">
    <div class="container">
        <div class="">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto dms-header-container">
                <p class="dms-header1">What can dox2U do?</p>
                <h3 class="size-fourty mb-3">dox2U: The <span class=underline-highlight1>Best</span> Way to Backup Tally Data</h3>
                <p class="common-heading" style="text-align: center;">Every business relies on Tally for financial records, compliance and operations. But without a proper backup strategy in place, your data is vulnerable </p>
            </div>
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class="container">
                    <!-- Problem Column -->
                    <div class="column column1">
                      <h2>Problem
                        <div class="sub-h2">
                            Think Your Data is Safe? Think Again
                        </div>
                      </h2>
                      <div class="content-para">
                        <div class="content-para1">
                            <p class="content-heading" >Losing Tally Data</p>
                            <p class="content-text">Hard drive failures, accidental deletions, and viruses can result in irreversible loss of your Tally data</p>
                        </div>
                        <div class="content-para2">
                            <p class="content-heading" >Unprotected Data</p>
                            <p class="content-text">Ransomware, hacking attacks or server crashes can impact the continuity of your business operations</p>
                        </div>
                      </div>
                      
                    </div>
                    
                    <!-- Solution Column -->
                    <div class="column column2">
                      <h2>Solution
                        <div  class="sub-h2">dox2U: Tally Backup for Peace of Mind</div>
                      </h2>
                      <div class="content-para" style="padding-top: 66px; padding-bottom: 66px;">
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Flexible scheduling for auto backups (daily, weekly, monthly)</p>
                        </div>
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Secure offsite storage of encrypted backups (at multiple locations) </p>
                        </div>
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Built-in antivirus detection</p>
                        </div>
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Background uploading of backups without opening Tally</p>
                        </div>
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Optimized storage using compressed zip files for Tally backups</p>
                        </div>
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Auto deletion of unwanted backups</p>
                        </div>
                      </div>

                      
                    </div>
                  </div>
            </div>
        </div>
    </div>
</section>

<section id="formtally" class="top-padd bottom-padd  formtally" *ngIf="!isLaunchTime">
    <div class="container">
        <div class=" tallyBackupContainer">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class=" d-flex justify-content-between align-items-start" style="-gap: 20px ">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 d-flex flex-column justify-content-center artopD">
                        <p class="dms-header1">Preview Offer</p>
                        <h3 class="size-fourty mb-3">Secure Your Tally Data Now!</h3>
                        <p class="common-heading">Don’t let data disasters derail your business. Get on our waitlist today and get 30% off on your subscription.</p>
                    </div>
                    <!-- <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div> -->
                    <div class="tallyBackupForm col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 d-flex flex-column justify-content-center artopD">
                        <div id="tallyDMSshow" *ngIf="waitingFormShow">
                            <p class="head1">Join the Waitlist</p>
                            <form [formGroup]="tallyBackupForm" (ngSubmit)="onSubmitTallyBackup($event)">
                                <div class="d-flex flex-column colmG">
                                    <div class="d-flex form-container w-100">
                                        <div class="d-flex flex-column w-50 input-container">
                                            <div class="nameInput d-flex flex-column form-group">
                                                <label>
                                                    <p class="label-field mb-1 tunset">First Name<span class="text-danger"
                                                        >*</span>
                                                    </p>
                                                </label>
                                                <input class="form-control" formControlName="firstname" maxlength="100" id="firstname" autocomplete="off" name="firstname" placeholder="Enter first name" (keypress)="resetFirstNameDivTP($event)">
                                                
                                                    <!-- <div *ngIf="dmsForm.get('firstname')?.hasError('required') && dmsForm.get('firstname').touched" class="error">This field cannot be empty</div> -->
                                            </div>
                                            <p class="mb-0 meserr text-danger" *ngIf="firstNameValidDivTP">
                                                This field cannot be empty
                                              </p>
                                              
                                            <p id="errfirstname1" class="mb-0  meserr text-danger"></p>
                                        </div>
                                        <div class="d-flex flex-column w-50 input-container">
                                            <div class="nameInput d-flex flex-column form-group">
                                                <label>
                                                    <p class="label-field mb-1 tunset">Last Name<span class="text-danger"
                                                            >*</span></p>
                                                </label>
                                                <input class="form-control" formControlName="lastname" maxlength="100" autocomplete="off" id="lastname" placeholder="Enter last name" name="lastname" (keypress)="resetLastNameDivTP($event)">
                                            </div>
                                            <p class="mb-0 meserr text-danger" *ngIf="lastNameValidDivTP">
                                                This field cannot be empty
                                              </p>
                                            <p id="errlastname1" class="mb-0  meserr"></p>
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex form-container w-100"> -->

                                        <div class="d-flex flex-column w-100 input-container">
                                            <div class="nameInput d-flex flex-column form-group">
                                                <label>
                                                    <p class="label-field mb-1 tunset">Email Address<span
                                                            style="color:red">*</span>
                                                    </p>
                                                </label>
                                                <input class="form-control" autocomplete="off" formControlName="email" id="tallyemail" placeholder="Enter your email address" name="tallyemail" (keypress)="resetEmailDivTP($event)">
                                            </div>
                                            <p class="mb-0 meserr text-danger" *ngIf="emailValidDivEmptyTP">
                                                This field cannot be empty
                                              </p>
                                              <p class="mb-0 meserr text-danger" *ngIf="emailValidDivInvalidTP">
                                                Please enter the valid email
                                              </p>
                                              
                                           
                                        </div>
                                        <div class="d-flex flex-column w-100 input-container">
                                            <div class="nameInput d-flex flex-column form-group">
                                                <label class="phn-label">
                                                    <p class="label-field mb-0 tunset">Phone Number<span class="text-danger"
                                                        >*</span></p>
                                                </label>

                                                <!-- <input name="phone" type="tel" id="phoneC" 
                                                    class="getting-started-input phn-inp inp100 form-control" placeholder="0987654321" formControlName="phone" (input)="resetPhoneDivTP($event)"
                                                    
                                                    maxlength="17"> -->

                                                    <ngx-intl-tel-input class="getting-started-input phn-inp inp100 form-control" (input)="resetPhoneDivTP($event)"
                                                    [cssClass]="'custom'"
                                                  
                                                    [enableAutoCountrySelect]="true"
                                                    [enablePlaceholder]="true"
                                                    [enableAutoCountrySelect]="true"
                                                    [searchCountryFlag]="true"
                                                    [searchCountryField]="[
                                                      SearchCountryField.Iso2,
                                                      SearchCountryField.Name
                                                    ]"
                                                    [selectFirstCountry]="false"
                                                    [selectedCountryISO]="CountryISO.India"
                                                    [maxLength]="15"
                                                    [phoneValidation]="true"
                                                    [separateDialCode]="separateDialCode"
                                                    [numberFormat]="PhoneNumberFormat.National"
                                                    name="phone"
                                                    formControlName="phone"
                                                    (countryChange)="onCountryChangeTP($event)"
></ngx-intl-tel-input>
                                            </div>
                                            <p class="mb-0 meserr text-danger" *ngIf="phoneValidDivTP">
                                                This field cannot be empty
                                              </p>

                                              <p class="mb-0 meserr text-danger" *ngIf="phoneValidationCheckDivTP">
                                                Please enter the valid number
                                              </p>

                                        </div>

                                        
                                    <p class="mb-1 comm-12">All input fields are mandatory <span class="text-danger">*</span></p>
                                    <div class="justify-content-center align-items-center d-flex submit-button">
                                        <button class="button-primary w-100 trydox" id="myButton" type="submit"><span
                                                class="hideDownload">Submit</span> </button>
                                        <div id="loader" class="spinner-border text-white"
                                            style="display:none;position:absolute;" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="tallyDMShide" *ngIf="waitingFormSubmitted" class="d-flex  flex-column justify-content-center align-items-center"
                        style="height:363px">
                        <p class="common-para text-center">Thank you for reaching out. Someone from our team will
                            connect with you shortly! </p>
                        <img src="../../../assets/Image/tallysuccess.svg">
                        </div>

                    </div>



                </div>
            </div>
        </div>
    </div>
</section>
<div *ngIf="isLaunchTime">
<section class="workspaces-banner document-handling-launch">
    <app-product-left-text-with-button [imgSrc] = "tallybackup_img" [contentH]="tallybackupHeroTextAfterLaunch"></app-product-left-text-with-button >
</section>

<section class="banner-new">
    <div class="container">
    <div class="">
        <div class=" col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
            <div class="tabs-section-Tbar ">
                <h3 class="size-fourty mb-3">dox2U: The Best Tally Backup Solution for You</h3>
                
            </div>
    <div class=" banner-new-container">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="integration-doc">
                <!-- <iframe width="803" height="452" [src]="videoUrl" frameborder="0" allowfullscreen></iframe> -->
                 <video id="myVideo" #video controls (click)="pauseVideo()">
        <source [src]="videoUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video>
            </div>
        </div>
       
    </div>
        </div>
    </div>
    
    </div>
    <div class="gradient-divider"></div>
    </section>

    <section id="formtally" class="download-and-install top-padd bottom-padd common-padd-tallyForm ">
        <div class="container p-0">
            <div class="">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <div class="d-flex justify-content-between align-items-start flex-wrap" style="-gap: 50px;">
                        <div
                            class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-start t-cen centText p-0">
    
                            <p class="dms-header1">DOWNLOAD PLUGIN</p>
                            <h3 class="size-fourty mb-3">Download and install dox2U’s Plugin for Tally Backup now!</h3>
                            <p class="common-heading">Getting started with dox2U’s Tally Plugin is simple. 
                                You can DIY or <a href="https://calendly.com/dox2u-dms/tally-plugin-installation" target="_blank" class="schedule-call"> schedule a call</a> with one of our experts.</p>
                            <div>
                                <div class="d-flex ">
                                    
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span> Free Trial</p>
                                </div>
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"><span class="horizontal-line"> </span>No credit card needed</p>
                                </div>
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"><span class="horizontal-line"> </span>Simple installation & activation</p>
                                </div>
                                <div class="d-flex ">                                    
                                    <p class="common-para text1 wunset"><span class="horizontal-line"> </span>Get Free dox2U cloud DMS</p>
                                </div>
                                <div>
                                    <p class="italic-Text">
                                        Supports Tally Backup for Tally ERP 9, Tally Prime and Tally on Cloud ; <span> For Sales & Support : +91-84488 57421</span>
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                        <div class="tallyBackupForm tallyBackupForm1 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 d-flex flex-column justify-content-center artopD">
                            <div id="tallyDMSshow" *ngIf="!submittedForm">
                                <form [formGroup]="dmsForm" (ngSubmit)="onSubmit()">
                                    <div class="d-flex flex-column colmG">
                                        <div class="d-flex form-container w-100">
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">First Name<span class="text-danger"
                                                            >*</span>
                                                        </p>
                                                    </label>
                                                    <input class="form-control" formControlName="firstname" maxlength="100" id="firstname" autocomplete="off" name="firstname" placeholder="Enter first name" (keypress)="resetFirstNameDivTP($event)">
                                                    
                                                        <!-- <div *ngIf="dmsForm.get('firstname')?.hasError('required') && dmsForm.get('firstname').touched" class="error">This field cannot be empty</div> -->
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="firstNameValidDivTP">
                                                    This field cannot be empty
                                                  </p>
                                                  
                                                <p id="errfirstname1" class="mb-0  meserr text-danger"></p>
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Last Name<span class="text-danger"
                                                                >*</span></p>
                                                    </label>
                                                    <input class="form-control" formControlName="lastname" maxlength="100" autocomplete="off" id="lastname" placeholder="Enter last name" name="lastname" (keypress)="resetLastNameDivTP($event)">
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="lastNameValidDivTP">
                                                    This field cannot be empty
                                                  </p>
                                                <p id="errlastname1" class="mb-0  meserr"></p>
                                            </div>
                                        </div>
                                        <!-- <div class="d-flex form-container w-100"> -->
    
                                            <div class="d-flex flex-column w-100 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Email Address<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    <input class="form-control" autocomplete="off" formControlName="email" id="tallyemail" placeholder="Enter your email address" name="tallyemail" (keypress)="resetEmailDivTP($event)">
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="emailValidDivEmptyTP">
                                                    This field cannot be empty
                                                  </p>
                                                  <p class="mb-0 meserr text-danger" *ngIf="emailValidDivInvalidTP">
                                                    Please enter the valid email
                                                  </p>
                                                  
                                               
                                            </div>
                                            <div class="d-flex flex-column w-100 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label class="phn-label">
                                                        <p class="label-field mb-0 tunset">Phone Number<span class="text-danger"
                                                            >*</span></p>
                                                    </label>
    
                                                    <!-- <input name="phone" type="tel" id="phoneC" 
                                                        class="getting-started-input phn-inp inp100 form-control" placeholder="0987654321" formControlName="phone" (input)="resetPhoneDivTP($event)"
                                                        
                                                        maxlength="17"> -->
    
                                                        <ngx-intl-tel-input class="getting-started-input phn-inp inp100 form-control" (input)="resetPhoneDivTP($event)"
                                                        [cssClass]="'custom'"
                                                      
                                                        [enableAutoCountrySelect]="true"
                                                        [enablePlaceholder]="true"
                                                        [enableAutoCountrySelect]="true"
                                                        [searchCountryFlag]="true"
                                                        [searchCountryField]="[
                                                          SearchCountryField.Iso2,
                                                          SearchCountryField.Name
                                                        ]"
                                                        [selectFirstCountry]="false"
                                                        [selectedCountryISO]="CountryISO.India"
                                                        [maxLength]="15"
                                                        [phoneValidation]="true"
                                                        [separateDialCode]="separateDialCode"
                                                        [numberFormat]="PhoneNumberFormat.National"
                                                        name="phone"
                                                        formControlName="phone"
                                                        (countryChange)="onCountryChangeTP($event)"
    ></ngx-intl-tel-input>
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="phoneValidDivTP">
                                                    This field cannot be empty
                                                  </p>
    
                                                  <p class="mb-0 meserr text-danger" *ngIf="phoneValidationCheckDivTP">
                                                    Please enter the valid number
                                                  </p>
    
                                            </div>
    
                                            
                                        <p class="mb-1 comm-12">All input fields are mandatory <span class="text-danger">*</span></p>
                                        <div class="justify-content-center align-items-center d-flex submit-button">
                                            <button class="button-primary w-100 trydox" id="myButton" type="submit"><span
                                                    class="hideDownload">Download</span> </button>
                                            <div id="loader" class="spinner-border text-white"
                                                style="display:none;position:absolute;" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="tallyDMShide" *ngIf="submittedForm" class="d-flex  flex-column justify-content-center align-items-center"
                                >
                                <p class="common-para text-center">Thank you! We’ve sent you an email with a link for downloading our Tally Plugin</p>
                                <img src="../../../assets/Image/tallysuccess.svg">
                            </div>
    
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="problem-sec">
        <div class="container productLeft">
            <div class="">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class=" d-flex align-items-start resp-container">
        
                  <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 p-0 plAlign leftpanel">
                    <div class="workspace-banner-content document-handling-banner-content">
                      <h6 class="heading-6 text-uppercase">The Problem</h6>
                      <h1 class="heading-1">Is Your Tally Data Safe?<span class="underline-highlight">Think Again.</span></h1>
                      
                      <p class="common-para">Every business relies on Tally for financial records, compliance and operations. But without a proper strategy to backup Tally data, your business is vulnerable</p>
        
                    </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 justify-content-center p-0">
                    <div class="d-flex flex-column problem-rightpanel">
                        <p class="small-heading">Losing Tally Data</p>
                        <p class="common-para mb-0">Hard drive failures or human error, like accidental deletions, can result in irreversible loss of your Tally data</p>
    
                    </div>
                    <div class="d-flex flex-column problem-rightpanel">
                        <p class="small-heading">Unprotected Data</p>
                        <p class="common-para mb-0">Ransomware, hacking attacks or server crashes can impact the continuity of your entire business operation</p>
    
                    </div>
                    <div class="d-flex flex-column problem-rightpanel">
                        <p class="small-heading">Time-Consuming Recovery</p>
                        <p class="common-para mb-0">Without Tally data backups, recovering lost Vouchers / Masters is very time-consuming and in some cases impossible!</p>
    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </section>
    <section class="backup-shield">
        <div class="container productLeft">
            <div class="">
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class="workspace-banner-content document-handling-banner-content text-center">
                    <h6 class="heading-6 text-uppercase">The Solution</h6>
                    <h1 class="heading-1">dox2U: Tally Backup Built for Peace of Mind</h1>
                    
                    <p class="common-para mb-1">Hard drives fail. Ransomware attacks. Don’t let one disaster derail your business. </p>
                    <p class="common-para">Protect your business with dox2U’s Automated Tally Backup — the smartest way to secure, protect and optimize your Tally data </p>
      
                  </div>
                <div class="shield-container  ">
                    <div class="shield-1 col-4">
                <div class="d-flex flex-column ">
                    <div class="img-shield content-container">
                        <img src="../../../assets/Image/schedule.svg" alt="Schedule" class="shild-img">
                        <p class="fs18" style="text-align: end;">Automated Backup Scheduling</p>
                        <img class="img-fluid" src="../../../assets/Image/schedule_content.svg">
                        <div class="hover-content">
                            <p class="hover-h1">Automated Backup Scheduling</p>
                            <p class="hover-p">Set automatic daily, weekly, or monthly backups with dox2U’s Tally backup solution. Stop worrying about forgetting to backup your data!</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="img-shield content-container">
                        <img src="../../../assets/Image/secure.svg" alt="Secure" class="shild-img">
                        <p class="fs18">Secure. Secure. Secure</p>
                        <img class="img-fluid" src="../../../assets/Image/secure_content.svg">
                        <div class="hover-content">
                            <p class="hover-h1">Secure. Secure. Secure</p>
                            <p class="hover-p">dox2U’s built-in antivirus scanning and encryption ensures that your Tally data backups are always protected</p>
                        </div>
                    </div>
                    
                </div>
                <div>
                    <div class="img-shield content-container">
                        <img src="../../../assets/Image/protected.svg" alt="Protected" class="shild-img">                       
                        <p class="fs18">Offsite Storage</p>
                        <img class="img-fluid" src="../../../assets/Image/protected_content.svg">
                    
                    <div class="hover-content">
                        <p class="hover-h1"> Offsite Storage</p>
                        <p class="hover-p">With secure offsite storage, your backups can be accessed whenever you need them—no matter where you are                        </p>
                    </div>
                </div>
                    
                </div>
            </div>
            <div class="shield-2 col-4">
                <img class="img-fluid" src="../../../assets/Image/shield_img.svg">
            </div>
            <div class="shield-3 col-4">
                <div class="d-flex flex-column">
                    <div class="img-shield content-container">
                        <img src="../../../assets/Image/cloud.svg" alt="Cloud" class="shild-img">
                
                        <p class="fs18">Optimized & Intelligent Storage</p>
                    <img class="img-fluid" src="../../../assets/Image/cloud_content.svg">
                    
                    <div class="hover-content">
                        <p class="hover-h1">Optimized & Intelligent Storage</p>
                        <p class="hover-p">Our intelligent algorithms ensure that your backups take up minimal space & your unwanted backups are periodically cleaned up                        </p>
                    </div>
                </div>
                </div>
                <div>
                    <div class="img-shield content-container">
                        <img src="../../../assets/Image/os.svg" alt="OS" class="shild-img">
                        <p class="fs18">Background Operations</p>
                        <img class="img-fluid" src="../../../assets/Image/os_content.svg">
                    
                    <div class="hover-content">
                        <p class="hover-h1"> Background Operations</p>
                        <p class="hover-p">With dox2U, your Tally backups happen seamlessly in the background without interrupting your work - whether your Tally is open or not</p>
                    </div>
                </div>
                </div>
                <div>
                    <div class="img-shield content-container">
                        <img src="../../../assets/Image/encrypted.svg" alt="Encrypted" class="shild-img">
                        <p class="fs18">Access Controls</p>
                        <img class="img-fluid" src="../../../assets/Image/encrypted_content.svg">
                    
                    <div class="hover-content">
                        <p class="hover-h1"> Access Controls</p>
                        <p class="hover-p">With strict permissions, only authorized personnel like Admins & Workspace Owners can access your Tally data backups</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="shield-containerResp">
                <div class="shield-2">
                    <img class="img-fluid" src="../../../assets/Image/shield_img.svg" class="shield-image">
                </div>
                <div class="shield-1">
            <div class="d-flex flex-column ">
                <div class="img-shield content-container">
                    <img src="../../../assets/Image/schedule.svg" alt="Schedule" class="shild-img">                   
                    <div class="hover-content1">
                        <p class="hover-h1">Automated Backup Scheduling</p>
                        <p class="hover-p">Set automatic daily, weekly, or monthly backups with dox2U’s Tally backup solution. Stop worrying about forgetting to backup your data!</p>
                    </div>
                </div>
            </div>
            <div>
                <div class="img-shield content-container">
                    <img src="../../../assets/Image/secure.svg" alt="Secure" class="shild-img">
                    <div class="hover-content1">
                        <p class="hover-h1">Secure. Secure. Secure</p>
                        <p class="hover-p">dox2U’s built-in antivirus scanning and encryption ensures that your Tally data backups are always protected</p>
                    </div>
                </div>
                
            </div>
            <div>
                <div class="img-shield content-container">
                    <img src="../../../assets/Image/protected.svg" alt="Protected" class="shild-img">                       
                
                <div class="hover-conten1t">
                    <p class="hover-h1"> Offsite Storage</p>
                    <p class="hover-p">With secure offsite storage, your backups can be accessed whenever you need them—no matter where you are                        </p>
                </div>
            </div>
                
            </div>
        </div>
       
        <div class="shield-3">
            <div class="d-flex flex-column">
                <div class="img-shield content-container">
                    <img src="../../../assets/Image/cloud.svg" alt="Cloud" class="shild-img">
                
                <div class="hover-content1">
                    <p class="hover-h1">Optimized & Intelligent Storage</p>
                    <p class="hover-p">Our intelligent algorithms ensure that your backups take up minimal space & your unwanted backups are periodically cleaned up                        </p>
                </div>
            </div>
            </div>
            <div>
                <div class="img-shield content-container">
                    <img src="../../../assets/Image/os.svg" alt="OS" class="shild-img">
                
                <div class="hover-content1">
                    <p class="hover-h1"> Background Operations</p>
                    <p class="hover-p">With dox2U, your Tally backups happen seamlessly in the background without interrupting your work - whether your Tally is open or not</p>
                </div>
            </div>
            </div>
            <div>
                <div class="img-shield content-container">
                    <img src="../../../assets/Image/encrypted.svg" alt="Encrypted" class="shild-img">
                
                <div class="hover-content1">
                    <p class="hover-h1"> Access Controls</p>
                    <p class="hover-p">With strict permissions, only authorized personnel like Admins & Workspace Owners can access your Tally data backups</p>
                </div>
            </div>
            </div>
        </div>
        </div>
                </div>
            </div>
        </div>
    </section>
    <section class="dox-competition" id="dox-competition">
        <div class="container special containerOverwrite p-0">
            <div class=" w-100">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto p-0">
                    <div class="workspace-banner-content document-handling-banner-content text-center">
                        <h6 class="heading-6 text-uppercase">dox2U vs The Competition</h6>
                        <h1 class="heading-1">Why choose dox2U?</h1>
                        
                        <p class="common-para mb-1">dox2U’s Plugin is the smartest way to secure, protect and backup your Tally data. Here’s why </p>         
                      </div>
    
                    <div class="card card-body tablemargin">
        <div class="pricing-table">
            <table class="feature-table tbl" id="expandable-table">
              <!-- #region --><thead>
                <tr class=" compare-plans-card ">
                <th><span class="compare-plan-heading">Features</span></th>

                    <th><span class="compare-plan-heading">Manual Backups</span></th>
                    <th><span class="compare-plan-heading">Local Backup TDLs</span></th>
                    <th> <span class="compare-plan-heading">Acronis</span></th>
                    <th> <span class="compare-plan-heading">dox2U</span></th>
                </tr>
                </thead>

                <tbody>
                <tr class="-white rBorder">
                    <td class="feature-content cursor-pointer">

                        <div class="tool-tip">
                            <span class="">Automated Backup Scheduling</span>

                        </div>
                    </td>

                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>

                </tr>
               
                <tr class="-white rBorder">
                    <td class="feature-content cursor-pointer">

                        <div class="tool-tip">
                            <span class="">Tally-Specific Solution</span>

                        </div>
                    </td>

                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                </tr>
                <!--pricing-table-content-->
                <tr class="rBorder">
                    <td class="feature-content cursor-pointer">
                        <span>Built-in Antivirus Protection</span>
                    </td>

                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                </tr>

                <tr class="-white rBorder">
                    <td class="feature-content cursor-pointer">No Mirroring Risks*</td>

                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                </tr>
                <tr class="rBorder">
                    <td class="feature-content cursor-pointer">Intelligent Cleanup of Unwanted Backups
                        
                    </td>

                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                </tr>
                <tr class="rBorder">
                    <td class="feature-content cursor-pointer">Offsite Storage
                        
                    </td>

                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                </tr>
                <tr class="rBorder">
                    <td class="feature-content cursor-pointer">Optimized Compression
                        
                    </td>

                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                </tr>
                <tr class="rBorder">
                    <td class="feature-content cursor-pointer">Seamless Tally Integration
                        
                    </td>

                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                </tr>
                <tr class="rBorder">
                    <td class="feature-content cursor-pointer">Background Operations
                        
                    </td>

                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                </tr>
                <tr class="rBorder">
                    <td class="feature-content cursor-pointer">Access Control
                        
                    </td>

                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/cross_red.svg" alt="checkmark" class="img-fluid"></td>
                    <td><img src="../../../assets/Image/Tick-Right.svg" alt="checkmark" class="img-fluid"></td>
                </tr>
           </tbody>
            </table>
        </div>

        
        </div>
        <p class="italic-Text">
            *Most other “Cloud Backup” solutions like Google Drive Sync via TDL, or Acronis, use a mirroring approach. This means that if the backup file is deleted or corrupted on the local system, the same change is reflected on Google Drive or Acronis! This can compromise the reliability of your Tally backup
        </p>
        </div>
        </div>
        </div>
    </section>
    <section class="turbo-learn">
        <div class="container special containerOverwrite p-0">
            <div class=" w-100">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto p-0">
                    <div class="workspace-banner-content document-handling-banner-content text-center">
                        <h6 class="heading-6 text-uppercase">Tally Plugin for Document Management </h6>
                        <h1 class="heading-1">Turbocharge Tally with dox2U!</h1>
                        
                        <p class="common-para mb-0">Easy cloud-based document management within Tally transactions. Link, control,</p>         
                        <p class="common-para mb-0">and access your documents seamlessly</p>      
                        <a routerLink="/tallydms"> <button class="button-transparent bg-white">Learn more <img src="../../../assets/Image/rightArrow_blue.svg" style="margin-left: 13px;"></button></a>   
                      </div>
                    </div>
                </div>
                </div>
                
    </section>

    <section class="feature-comparision common-padd  bottmp">
        <div class="container p-0">
            <div class="w-100 ">
                <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto p-0">
                    <div class="">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
                            <div class="feature-comparision-top d-flex flex-column align-items-center mb-5 pb-3">
                                <h2 class="mb-3"><b>Frequently asked questions</b></h2>
                            </div>
                            <div class="feature-comparision-Acordian">
                                <div id="accordion" class="accordion">
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseOne">
                                        <h4 class="accordian-headings mb-0" style="color: rgb(88, 88, 88);">How does dox2U protect my Tally backups?</h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseOne" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion" >
                                        <p>dox2U protects your Tally data by creating automated backups that are first scanned for viruses using our antivirus detection algorithm and then securely encrypted & stored in an offsite location. Unlike other Tally backup solutions, dox2U built-in antivirus detection, industry-grade encryption and removes the risk of data loss due to mirroring <a (click)="scrollToWhyChooseDox()" style="color: #36F; cursor: pointer;">(see table above)</a> 
                                        </p>
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseTwo">
                                        <h4 class="accordian-headings mb-0">How can I restore my lost Tally data and Tally backup with dox2U?</h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                       <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseTwo" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>Restoring your lost Tally data with dox2U’s Tally Backup Solution is simple. After logging onto your dox2U dashboard, or the Manage Backups section from within your Tally, just download the stored Tally backup. Move the downloaded folder into the folder where your Tally data resides and you’ll be up and running in no time! 
                                        </p>
    
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseThree">
                                        <h4 class="accordian-headings mb-0">How do I back up my Tally data with dox2U?</h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseThree" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>Backing up your Tally data with dox2U is fully automated. You can set up flexible backup rules that give you the flexibility to choose which Tally Company should be backed up with what frequency (daily, weekly or monthly). Once set up, dox2U takes care of the rest and automatic carries out your Tally data backup as per the set schedule. Your data is securely scanned for threats, encrypted and then stored for easy restoration & retrieval.</p>
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFour">
                                        <h4 class="accordian-headings mb-0">Why should I choose dox2U for my Tally data backup?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img">
                                    </div>
                                    <div id="collapseFour" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>dox2U is the smartest choice for Tally data backup because it combines automation, security, and ease of use. With built-in antivirus detection, encryption and access controls, your Tally data backups are always secure against any security threats. Automated scheduling along with dox2U’s intelligent compression & cleanup algorithms ensure that you never forget a backup and don’t have to worry about your storage running out!
                                        </p>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                   
                </div>

            </div>
        </div>
    </section>
</div>
<app-gradient-divider></app-gradient-divider>
<app-countdown-timer (countdownEnded)="onCountdownEnd($event)" class="d-none"></app-countdown-timer>