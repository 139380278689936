import { Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as AOS from 'aos';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ZohoService } from '../../zoho.service';
import { EmailService } from 'src/app/email.service';
import { CustomerIOService } from 'src/app/customer-io.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { PhoneNumberUtil } from "google-libphonenumber";
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-dox2-u-for-tally',
  templateUrl: './dox2-u-for-tally.component.html',
  styleUrls: ['./dox2-u-for-tally.component.scss']
})
export class Dox2UForTallyComponent implements OnInit {
  jsonLD: SafeHtml | undefined;

  /*
     interface path for images 
  */
  imgSrc: string = '../../../assets/Image/launch-soon-tallyBackup.svg'
  imagePathTally: string='../../../assets/Image/tally-dms.svg';

  imagePathList: string='../../../assets/Image/easy-one-click.svg';

  imagePathList1: string='../../../assets/Image/stop-printing.svg';

  imagePathList2: string='../../../assets/Image/anytime.svg';

  imagePathList3: string='../../../assets/Image/security1.svg';

  imagePathList4: string='../../../assets/Image/go-digital.svg'

  imagePathList5: string='../../../assets/Image/reduce-cost1.svg'

  dmsForm: FormGroup;
  tallyPrimeForm: FormGroup;
  isLoading = false;
  firstNameValidDiv: boolean = false;
  lastNameValidDiv: boolean = false;
  emailValidDivEmpty: boolean = false;
  emailValidDivInvalid: boolean = false;
  phoneValidDiv: boolean = false;
  submittedForm: boolean = false;
 // videoUrl: SafeResourceUrl;
  //videoElement!: HTMLIFrameElement;
  videoElement!: HTMLVideoElement;
  userInteracted: boolean = false;
  selectedFlagDiv: boolean = false;
  partnerFormSubmitted: boolean = false;
  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode!: false;
  firstNameValidDivTP: boolean = false;
  lastNameValidDivTP: boolean = false;
  emailValidDivEmptyTP: boolean = false;
  emailValidDivInvalidTP: boolean = false;
  phoneValidDivTP: boolean = false;
  phoneValidationCheckDivTP: boolean = false;
  companyNameValidDivTP: boolean = false;
  changeOpacity: boolean = false;
  countryName: string = 'India';
  dialCode: Number = 91;
  contactNumber: any;
  countryNameTP: string = 'India';
  dialCodeTP: Number = 91;
  contactNumberTP: any;
  @ViewChild('selectedFlag', { static: false })
  selectedFlag!: ElementRef;
  @ViewChild('text-heading') textHeading!: ElementRef;
  @ViewChild('stepperItem')
  stepperItem!: ElementRef;
  isCountdownFinished = false;
  isHeadingEleminViewport1: boolean = false;
  isHeadingEleminViewport2: boolean = false;
  isHeadingEleminViewport3: boolean = false;
  isHeadingEleminViewport4: boolean = false;
  isHeadingEleminViewport5: boolean = false;
  isheadingElementContainer: boolean = false;
  private headingContainer1!: HTMLElement;

  partnerForm: FormGroup;
  firstNamePValidDiv: boolean = false;
  lastNamePValidDiv: boolean = false;
  organizationPValidDiv: boolean = false;
  tallyemailPValidDiv: boolean = false;
  phonePValidDiv: boolean = false;
  phonePValidationDiv: boolean = false;
  countryPValidDiv: boolean = false;
  tallyemailPEmptyDiv: boolean = false;
  tallyemailPInvalidDiv: boolean = false;
  partnerFormShow: boolean = true;
  phoneValidationCheckDiv: boolean = false;
  phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

  videoUrl: string = 'https://www.dox2u.com/e-docs/Tally_marketing_video.mp4';
  constructor(private meta: Meta, private seoService: SEOService, private router: Router, private renderer2 : Renderer2, private partnerfb: FormBuilder, private el: ElementRef, private sanitizer: DomSanitizer, private fb: FormBuilder, private zohoService: ZohoService, private titleService: Title, private emailService: EmailService, private customerIOService: CustomerIOService, @Inject(DOCUMENT) private _document: Document) {
    /*
     * Cannonical tag
     */
    
    /*
     form validations
    */
    this.dmsForm = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
    });

    this.tallyPrimeForm = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      companyname:['', [Validators.required]],
      email: ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
        phone: ['', [Validators.required, Validators.maxLength(15)]],
    });

    this.partnerForm = this.partnerfb.group({
      firstnameP: ['', [Validators.required]],
      lastnameP: ['', [Validators.required]],
      organizationP: ['', [Validators.required]],
      tallyemailP: ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
      countryP: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(/^\d+$/), Validators.maxLength(15)]],
    });

   // const youtubeVideoUrl = 'https://www.youtube.com/embed/sxPL6es7oQM?enablejsapi=1';

   //const youtubeVideoUrl = 'https://dox2u.com/e-docs/Tally_Add-On_Overview.mp4';

    //this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(youtubeVideoUrl);
  
  
  }


  /**
   * To check countrywise phone number validation 
   */

  checkPhonenoValidation() {
    let phoneNumber = this.dmsForm.get('phone')?.value;
    let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
    let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);

  //  if(!isPhonenoValid) {
  //   this.phoneValidationCheckDiv = true;
  //   event?.preventDefault();
  //  }
  }

  onCountdownEnd(event: boolean): void {
    if (event) {
      this.isCountdownFinished = true;
      if(window.location.href.endsWith('tally')) {
        this.router.navigate(['/tallydmsplugin'])
      }
      
    }
  }

  checkPhonenoValidationtally() {
    // let phoneNumber = this.tallyPrimeForm.get('phone')?.value;
    // let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
    // let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);

  //  if(!isPhonenoValid) {
  //   this.phoneValidationCheckDiv = true;
  //   event?.preventDefault();
  //  }
  }
  private previousScrollPosition = 0;
  // = document.getElementById('myVideo') as HTMLElement;
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const isVideoInViewport = this.isElementInViewport(this.videoElement);
  //   if(!this.userInteracted) {
    if (!isVideoInViewport) {     
      //this.videoElement.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
      this.videoElement.pause();  
    }
    // else {
    //   this.videoElement.pause();
    // }
  // }
  
    
    let headingElementContainer = document.getElementById('headingElementContainer') as HTMLElement;
    this.isheadingElementContainer = this.isHeadingInViewport1(headingElementContainer);

    let headingElement1 = document.getElementById('headingElement1') as HTMLElement;
    this.isHeadingEleminViewport1 = this.isHeadingInViewport2(headingElement1);

    let headingElement2 = document.getElementById('headingElement2') as HTMLElement;
    this.isHeadingEleminViewport2 = this.isHeadingInViewport2(headingElement2);
 
    let headingElement3 = document.getElementById('headingElement3') as HTMLElement;
    this.isHeadingEleminViewport3 = this.isHeadingInViewport2(headingElement3);

    let headingElement4 = document.getElementById('headingElement4') as HTMLElement;
    this.isHeadingEleminViewport4 = this.isHeadingInViewport2(headingElement4);

    let headingElement5 = document.getElementById('headingElement5') as HTMLElement;
    this.isHeadingEleminViewport5 = this.isHeadingInViewport5(headingElement5);

    //this.stepperItem.nativeElement.style.setProperty('--stepper-gradient', `linear-gradient(to bottom, #36F ${percentageInViewport * 100}%, transparent 100%)`);
  }

  pauseVideo() {
    this.userInteracted = !this.userInteracted;
  }



  private isElementInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    
  }

  private isHeadingInViewport(element: HTMLElement): boolean {
    const headingRect = element.getBoundingClientRect();
    return (
      headingRect.top <= 300 &&
      //headingRect.left >= 0 &&
      headingRect.bottom <= 400 
     // headingRect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    
  }

  private isHeadingInViewport2(element: HTMLElement): boolean {
    const headingRect = element.getBoundingClientRect();
    return (
      headingRect.top <= 760 &&
      //headingRect.left >= 0 &&
      headingRect.bottom >= 500 
     // headingRect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    
  }

  private isHeadingInViewport5(element: HTMLElement): boolean {
    const headingRect = element.getBoundingClientRect();
    return (
      headingRect.top <= 760 &&
      //headingRect.left >= 0 &&
      headingRect.bottom >= 320 
     // headingRect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    
  }

  private isHeadingInViewport1(element: HTMLElement): boolean {
    const headingRect = element.getBoundingClientRect();
    return (
      headingRect.bottom >= 380 &&
      headingRect.top <= 346
    );
  }

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      //element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center'});
      // let boldHeadingClass = 'bold-heading-' + elementId;
      //   element.classList.add(boldHeadingClass);
      console.log("elementFound", element)
      const viewportHeight = window.innerHeight;
      const elementHeight = element.clientHeight;
      const yOffset = (viewportHeight - elementHeight) / 2;
      const scrollOptions: ScrollToOptions = {
      top: element.offsetTop - yOffset - 100,
      behavior: 'smooth',
    };
      window.scrollTo(scrollOptions);
    }
  }

  checkPhonenoValidationPartner() {
    let phoneNumber = this.partnerForm.get('phone')?.value;
    let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
    let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);

  //  if(!isPhonenoValid) {
  //   this.phonePValidationDiv = true;
  //  }
  }

  validatePhoneNumber(phoneNumber: string, countryCode: any) {
    // const parsedNumber = this.phoneNumberUtil.parseAndKeepRawInput(phoneNumber, countryCode);
    //   const isValidNumber = this.phoneNumberUtil.isValidNumber(parsedNumber);
    //   console.log("phone number is", parsedNumber, isValidNumber);
    //   const numberType = this.phoneNumberUtil.getNumberType(parsedNumber);
    //   return isValidNumber;

      
  }
  ngAfterViewInit() {
    // this.videoElement = this.el.nativeElement.querySelector('iframe');
    // if (this.videoElement) {
    //   console.log('Video element found:', this.videoElement);
    // } else {
    //   console.error('Video element not found!');
    // }

    this.videoElement = this.el.nativeElement.querySelector('#myVideo');
  }
  

  /*
  resetting error message of last name input on keypress
  */
  resetLastNameDiv(event: Event) {
    this.lastNameValidDiv = false;
  }

  /*
  resetting error message of first name input on keypress
  */
  resetFirstNameDiv(event: Event) {
    this.firstNameValidDiv = false;
  }

  /*
  resetting error message of email input on keypress
  */
  resetEmailDiv(event: Event) {
    this.emailValidDivEmpty = false;
    this.emailValidDivInvalid = false;
  }

  /*
  resetting error message of phone input on keypress
  */
  resetPhoneDiv(event: Event) {
    this.phoneValidDiv = false;
    this.phoneValidationCheckDiv = false;
  }

  /*
  resetting error message of firstname input on keypress
  */
  resetFirstNamePDiv(event: Event) {
    this.firstNamePValidDiv = false;
  }

  /*
  resetting error message of lastname input on keypress
  */
  resetLastNamePDiv(event: Event) {
    this.lastNamePValidDiv = false;
  }

  /*
  resetting error message of organisation name input on keypress
  */
  resetOrganizationPDiv(event: Event) {
    this.organizationPValidDiv = false;
  }

  /*
  resetting error message of email input on keypress
  */
  resetTallyEmailPDiv(event: Event) {
    this.tallyemailPInvalidDiv = false;
    this.tallyemailPEmptyDiv = false;

  }

  /*
  resetting error message of phone input on keypress
  */
  resetPhoneCDiv(event: Event) {
    this.phonePValidDiv = false;
    this.phonePValidationDiv = false;
  }

  /*
  resetting error message of country input on keypress
  */
  resetCountryPDiv(event: Event) {
    this.countryPValidDiv = false;
  }

  /*
    This method will recheck all the validations on form and send the payload if get the success response.
  */
  onSubmitParther() {
    if(this.partnerForm.get('firstnameP')?.hasError('required')) {
      this.firstNamePValidDiv = true;
    }
  
    if(this.partnerForm.get('lastnameP')?.hasError('required')) {
      this.lastNamePValidDiv = true;
    }
  
    if(this.partnerForm.get('organizationP')?.hasError('required')) {
      this.organizationPValidDiv = true;
    }
  
    if(this.partnerForm.get('tallyemailP')?.hasError('email')) {
      this.tallyemailPInvalidDiv = true;
    }

    if(this.partnerForm.get('tallyemailP')?.hasError('pattern')) {
      this.tallyemailPInvalidDiv = true;
    }

    if(this.partnerForm.get('tallyemailP')?.hasError('required')) {
      this.tallyemailPEmptyDiv = true;
    }
  
    if(this.partnerForm.get('countryP')?.hasError('required')) {
      this.countryPValidDiv = true;
    }

    if(this.partnerForm.get('phone')?.hasError('required')) {
      this.phonePValidDiv = true;
    }

    //this.checkPhonenoValidationPartner();
    let phoneNumber = this.partnerForm.get('phone')?.value;
    let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
    let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);

  //  if(!isPhonenoValid) {
  //   this.phonePValidationDiv = true;
  //  }
    let dialCode = localStorage.getItem("DialCode");
    let contactNumber = '';

      if (this.partnerForm.valid && !this.phonePValidationDiv) {
        this.partnerFormSubmitted = true;
        this.partnerFormShow = false;
        dialCode = dialCode || "91";
        contactNumber = "+" + dialCode + this.partnerForm.value.phone;
        
        const firstName = this.partnerForm.value.firstnameP;
        const lastName = this.partnerForm.value.lastnameP;
        const fullName = `${firstName} ${lastName}`;
        const email = this.partnerForm.value.tallyemailP;
        const orgName = this.partnerForm.value.organizationP;
        const phoneNo = contactNumber;
        const country = this.partnerForm.value.countryP;
        const WS_SubscriptionStatus = "";
        const leadSource = "WS-PartnerRequest";
        const address1 = '';
        const address2 = '';        
        const imgContent_security = '';
        const state = '';
        const companySize = '';
        const jobTitle = '';
        const salutation = '';
        const message1 = '';
        const message2 = '';

        this.zohoService.sendUserData(firstName, lastName, fullName, 
          email, 
          WS_SubscriptionStatus, 
          leadSource, 
          address1,
          address2,
          phoneNo,
          imgContent_security,
          state,
          country,
          orgName,
          companySize,
          jobTitle,
          salutation,
          message1,
          message2)
        .subscribe(
          (response) => {
            console.log('zoho lead sent:', response);
          },
          error => {
            console.error('Email Error:', error);
          }
        );

        const recipientEmail = this.partnerForm.value.tallyemailP;
        const messageId = environment.PartnerFormsMessageID;
        const messageData = { First_Name: this.partnerForm.value.firstnameP };

  
        this.emailService.sendEmail(recipientEmail, messageId, messageData, email)
        .subscribe(
          (response) => {
            console.log('Email sent:', response);
          },
          (error) => {
            console.error('Email Error:', error);
          }
        );

        this.customerIOService.updateUserLeadSource(email, leadSource)
              .subscribe(
                (response) => {
                  console.log('User lead source updated successfully:', response);
                },
                (error) => {
                  console.error('Error updating user lead source:', error);
                }
              );
          
        }
  
        
      }

      onCountryChange(event: any) {
        this.countryName = event.name;
        this.dialCode = event.dialCode;
        this.contactNumber = event.e164Number;
        console.log('Selected Country ISO:', event.iso2);
        console.log('Selected Country Name:', this.countryName);
        console.log('Selected Country Dial Code:', this.dialCode);
      }


  /*
  This method will send the payload for zoho, customer.io and email service on successful validations of all input fields
  */
  onSubmit() {
  if(this.dmsForm.get('firstname')?.hasError('required')) {
    this.firstNameValidDiv = true;
  }

  if(this.dmsForm.get('lastname')?.hasError('required')) {
    this.lastNameValidDiv = true;
  }

  if(this.dmsForm.get('email')?.hasError('required')) {
    this.emailValidDivEmpty = true;
  }

  if(this.dmsForm.get('email')?.hasError('email')) {
    this.emailValidDivInvalid = true;
  }

  if(this.dmsForm.get('email')?.hasError('pattern')) {
    this.emailValidDivInvalid = true;
  }

  if(this.dmsForm.controls['phone'].invalid) {
    this.phoneValidationCheckDiv = true;
    event?.preventDefault();
   }

  if(this.dmsForm.get('phone')?.hasError('required')) {
    this.phoneValidDiv = true;
    this.phoneValidationCheckDiv = false;
  }



  //this.checkPhonenoValidation();
  //let phoneNumber = this.dmsForm.get('phone')?.value;
   // let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
    //let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);

  // console.log("isPhonenoValid", isPhonenoValid)



  // let countryName = localStorage.getItem("CountryName");
  // let dialCode = localStorage.getItem("DialCode");
 // let contactNumber = '';

    if (this.dmsForm.valid && !this.phoneValidationCheckDiv) {
        this.submittedForm = true;
        
        //contactNumber = "+" + this.dialCode + this.dmsForm.value.phone;
        const phoneValue = this.dmsForm.value.phone;
      //  contactNumber = `+${this.dialCode}${phoneValue}`;
      this.contactNumber = phoneValue.internationalNumber;
        console.log("contactNumber", this.contactNumber)
        console.log("dialCode", this.dialCode)
        console.log("phoneValue", this.contactNumber)
        console.log("countryName", this.countryName)


        /*
        payload for zoho service
      */
        const firstName = this.dmsForm.value.firstname;
        const lastName = this.dmsForm.value.lastname;
        const fullName = `${firstName} ${lastName}`;
        const email = this.dmsForm.value.email;
        const WS_SubscriptionStatus = "";
        const leadSource = 'WS-Downloadtally-DMS';
        const address1 = '';
        const address2 = '';
        const phoneNo = this.contactNumber;
        const imgContent_security = '';
        const state = '';
        const country = this.countryName;
        const orgName = '';
        const companySize = '';
        const jobTitle = '';
        const salutation = '';
        const message1 = '';
        const message2 = '';

        /*
          function to send data to zoho service
        */

        this.zohoService.sendUserData(firstName, lastName, fullName, 
          email, 
          WS_SubscriptionStatus, 
          leadSource, 
          address1,
          address2,
          phoneNo,
          imgContent_security,
          state,
          country,
          orgName,
          companySize,
          jobTitle,
          salutation,
          message1,
          message2)
        .subscribe(
          (response) => {
            console.log('zoho lead sent:', response);
          },
          error => {
            console.error('Email Error:', error);
          }
        );

        /*
        payload for email service
        */ 
        const recipientEmail = this.dmsForm.value.email;
        const messageId = environment.DownloadTallyMessageID;
        const lastname = this.dmsForm.value.lastname;
        const firstname = this.dmsForm.value.firstname;
        const ownerFullName = firstname+' '+lastname;
        console.log ('fullname', ownerFullName);
        

        const messageData = { First_Name: this.dmsForm.value.firstname,  
                              ownernName: ownerFullName,
                              ownerEmail: this.dmsForm.value.email};

          /*
          function to send data to email service
        */
        this.emailService.sendEmail(recipientEmail, messageId, messageData, email)
          .subscribe(
            (response) => {
              console.log('Email sent:', response);
            },
            (error) => {
              console.error('Email Error:', error);
            }
          );

            
        /*
          function to send data to customer.io
        */
            this.customerIOService.updateUserLeadSource(email, leadSource)
              .subscribe(
                (response) => {
                  console.log('User lead source updated successfully:', response);
                },
                (error) => {
                  console.error('Error updating user lead source:', error);
                }
              );
      }

      
    }


    
    onCountryChangeTP(event: any) {
      this.countryNameTP = event.name;
      this.dialCodeTP = event.dialCode;
      this.contactNumberTP = event.e164Number;
      console.log('Selected Country ISO:', event.iso2);
      console.log('Selected Country Name:', this.countryNameTP);
      console.log('Selected Country Dial Code:', this.dialCodeTP);
    }

    resetFirstNameDivTP(event: Event) {
      this.firstNameValidDivTP = false;
    }

    resetLastNameDivTP(event: Event) {
      this.lastNameValidDivTP = false;
    }

    resetEmailDivTP(event: Event) {
      this.emailValidDivEmptyTP = false;
      this.emailValidDivInvalidTP = false;
    }

    resetPhoneDivTP(event: Event) {
      this.phoneValidDivTP = false;
      this.phoneValidationCheckDivTP = false;
    }

    resetCompanyNameDivTP(event: Event) {
      this.companyNameValidDivTP = false;
    }

    onSubmitTallyPrime() {
      if(this.tallyPrimeForm.get('firstname')?.hasError('required')) {
        this.firstNameValidDivTP = true;
      }
    
      if(this.tallyPrimeForm.get('lastname')?.hasError('required')) {
        this.lastNameValidDivTP = true;
      }

      if(this.tallyPrimeForm.get('companyname')?.hasError('required')) {
        this.companyNameValidDivTP = true;
      }
    
      if(this.tallyPrimeForm.get('email')?.hasError('required')) {
        this.emailValidDivEmptyTP = true;
      }
    
      if(this.tallyPrimeForm.get('email')?.hasError('email')) {
        this.emailValidDivInvalidTP = true;
      }
    
      if(this.tallyPrimeForm.get('email')?.hasError('pattern')) {
        this.emailValidDivInvalidTP = true;
      }

      if(this.tallyPrimeForm.controls['phone'].invalid) {
        this.phoneValidationCheckDivTP = true;
        event?.preventDefault();
       }
    
      if(this.tallyPrimeForm.get('phone')?.hasError('required')) {
        this.phoneValidDivTP = true;
        this.phoneValidationCheckDivTP = false;

        
      }
    
      //this.checkPhonenoValidation();
      // let phoneNumber = this.tallyPrimeForm.get('phone')?.value;
      //   let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
      //   let isPhonenoValid = this.validatePhoneNumber(phoneNumber, this.dialCode);
    
      //  console.log("isPhonenoValid", isPhonenoValid)
    
       
    
      // let countryName = localStorage.getItem("CountryName");
      // let dialCode = localStorage.getItem("DialCode");
      // let contactNumber = '';
    
        if (this.tallyPrimeForm.valid && !this.phoneValidationCheckDivTP) {
            this.partnerFormSubmitted = true;
            this.partnerFormShow = false;
            const phoneValueTP = this.tallyPrimeForm.value.phone;
            //  contactNumber = `+${this.dialCode}${phoneValue}`;
            this.contactNumberTP = phoneValueTP.internationalNumber;
              console.log("contactNumber", this.contactNumberTP)
              console.log("dialCode", this.dialCodeTP)
              console.log("phoneValue", this.contactNumberTP)
              console.log("countryName", this.countryNameTP)
    
            /*
            payload for zoho service
          */
            const firstName = this.tallyPrimeForm.value.firstname;
            const lastName = this.tallyPrimeForm.value.lastname;
            const fullName = `${firstName} ${lastName}`;
            const email = this.tallyPrimeForm.value.email;
            const WS_SubscriptionStatus = "";
            const leadSource = 'WS-Getintouch';
            const address1 = '';
            const address2 = '';
            const phoneNo = this.contactNumberTP;
            const imgContent_security = '';
            const state = '';
            const country = this.countryNameTP;
            const orgName = this.tallyPrimeForm.value.companyname;
            const companySize = '';
            const jobTitle = '';
            const salutation = '';
            const message1 = '';
            const message2 = '';
    
            /*
              function to send data to zoho service
            */
    
            this.zohoService.sendUserData(firstName, lastName, fullName, 
              email, 
              WS_SubscriptionStatus, 
              leadSource, 
              address1,
              address2,
              phoneNo,
              imgContent_security,
              state,
              country,
              orgName,
              companySize,
              jobTitle,
              salutation,
              message1,
              message2)
            .subscribe(
              (response) => {
                console.log('zoho lead sent:', response);
              },
              error => {
                console.error('Email Error:', error);
              }
            );
    
            /*
            payload for email service
            */ 
            const recipientEmail = this.tallyPrimeForm.value.email;
            const messageId = environment.PartnerFormsMessageID;
            const lastname = this.tallyPrimeForm.value.lastname;
            const firstname = this.tallyPrimeForm.value.firstname;
            const ownerFullName = firstname+' '+lastname;
            console.log ('fullname', ownerFullName);
            
    
            const messageData = { First_Name: this.tallyPrimeForm.value.firstname,  
                                  ownernName: ownerFullName,
                                  ownerEmail: this.tallyPrimeForm.value.email};
    
              /*
              function to send data to email service
            */
            this.emailService.sendEmail(recipientEmail, messageId, messageData, email)
              .subscribe(
                (response) => {
                  console.log('Email sent:', response);
                },
                (error) => {
                  console.error('Email Error:', error);
                }
              );
    
                
            /*
              function to send data to customer.io
            */
                this.customerIOService.updateUserLeadSource(email, leadSource)
                  .subscribe(
                    (response) => {
                      console.log('User lead source updated successfully:', response);
                    },
                    (error) => {
                      console.error('Error updating user lead source:', error);
                    }
                  );
          }
    
          
        }
  




  ngOnInit() {
   
   // this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/tally'});
    this.createLinkForCanonicalURL(); /** To add canonical tags */
    let script = this.renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `{
      "@context": "https://schema.org/",
      "@type": "SoftwareApplication",
      "name": "dox2U's Tally Plugin",
      "url": "https://www.dox2u.com/tally",
      "image": "https://www.dox2u.com/assets/Image/tally-dms.svg",
      "description": "dox2u's Tally plugin integrates your Tally accounting software with dox2u's cloud-based Document Management System (DMS) to improve document management and streamline your accounting workflows.",
      "applicationCategory": "Business & Commerce",
      "operatingSystem": [
        "Windows" 
      ],
      "features": [
        {
          "@type": "Feature",
          "name": "Direct document attachment",
          "description": "Attach documents directly to vouchers and masters in Tally."
        },
        {
          "@type": "Feature",
          "name": "Seamless document access",
          "description": "View documents within Tally with a single click."
        },
        {
          "@type": "Feature",
          "name": "Secure collaboration",
          "description": "Share and collaborate on documents with secure access control."
        },
        {
          "@type": "Feature",
          "name": "Enhanced security and compliance",
          "description": "Benefit from dox2u's security and compliance features."
        }
      ],
      "creator": {
        "@type": "Organization",
        "name": "dox2U",
        "url": "https://www.dox2u.com/"
      },
    
     "serviceArea": {
        "@type": "Country",
        "name": "IN"
      },
      "priceSpecification": {
        "@type": "SubscriptionOffer",
        "price": 292,
        "priceCurrency": "INR",
        "availability": "InStock"
      },
      "softwareVersion": "Latest",
      "sameAs": [
        "https://www.dox2u.com/tally-plugin",
        "https://www.facebook.com/dox2u",
        "https://twitter.com/dox2u"
      ]
    
    }`

    let scriptProduct = this.renderer2.createElement('script');
    scriptProduct.type = `application/ld+json`;
    scriptProduct.text = `{
       "@context": "https://schema.org/", 
      "@type": "Product", 
      "name": "dox2U Tally Plugin",
      "image": "https://www.dox2u.com/assets/Image/tally-dms.svg",
      "description": "dox2U's Tally Plugin integrates your Tally accounting software with dox2U's cloud-based Document Management System (DMS) to improve document management and streamline your accounting workflows.",
      "brand": {
          "@type": "Brand",
          "name": "dox2U"
        },
      "offers": {
      "@type": "AggregateOffer",
      "url": "",
      "priceCurrency": "INR",
      "lowPrice": "292"
      }
      
    }`

    let scriptTally = this.renderer2.createElement('script');
    scriptTally.type = `application/ld+json`;
    scriptTally.text = `{
      "@context": "https://schema.org/", 
      "@type": "BreadcrumbList", 
      "itemListElement": [{
        "@type": "ListItem", 
        "position": 1, 
        "name": "Home page",
        "item": "https://dox2u.com/"  
      },{
        "@type": "ListItem", 
        "position": 2, 
        "name": "Product page (dox2U's Tally plugin)",
        "item": "https://dox2u.com/tally"  
      }]
    }`

    let scriptFnQ = this.renderer2.createElement('script');
    scriptFnQ.type = `application/ld+json`;
    scriptFnQ.text = `{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is a Document Management System?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "A document management system (DMS) is a software solution that enables organizations to store, organize, manage, and track their digital documents and files in a centralized and secure manner. It provides a structured approach to document management, allowing users to create, capture, store, retrieve, and collaborate on documents efficiently."
        }
      },{
        "@type": "Question",
        "name": "What is the purpose of the dox2U Tally Plugin?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "dox2U’s Tally Plugin is designed to enhance the functionality of Tally accounting software by providing additional features for Document Management and integrates Tally with our award-winning cloud based Document Management System."
        }
      },{
        "@type": "Question",
        "name": "How does dox2U’s Tally DMS Plugin integrate with Tally accounting software?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "dox2U’s Tally Plugin integrates seamlessly with Tally accounting software through a simple installation process. Once installed, it adds features and functionalities to directly attach & view documents within the Tally interface.."
        }
      },{
        "@type": "Question",
        "name": "What features and functionalities does dox2U’s Tally cloud DMS offer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "dox2U’s Tally Plugin offers a range of features, such as attaching documents from vouchers & masters, one-click viewing of documents from Tally, sharing & collaborating on documents, etc.. It streamlines document processes, improves efficiency, and provides valuable insights for better decision-making."
        }
      },{
        "@type": "Question",
        "name": "Is dox2U Tally cloud DMS compatible with the latest version of Tally?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, dox2U’s Tally Plugin is regularly updated to ensure compatibility with the latest version of Tally accounting software and supports Tally ERP 9, Tally Prime (Release 4 as well) and also can be deployed with Tally on Cloud. It is designed to work seamlessly with Tally's most up-to-date features and functionalities."
        }
      },{
        "@type": "Question",
        "name": "Does dox2U’s Tally Plugin support both Tally's desktop and online versions?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, dox2U’s Tally Plugin supports both Tally's desktop (Silver & Gold) and online versions. It can be used with Tally installed on a local system or accessed through the cloud-based Tally."
        }
      },{
        "@type": "Question",
        "name": "How easy is it to install and configure dox2U Tally DMS Software?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The installation and configuration process of dox2U’s Tally Plugin is user-friendly and straightforward. It typically involves downloading and installing the plugin, followed by a simple setup and activation process within Tally accounting software."
        }
      },{
        "@type": "Question",
        "name": "Are there any additional costs associated with using dox2U Tally Plugin?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "No, all you need is to install the Plugin and subscribe to one of our subscription plans depending on your use case."
        }
      },{
        "@type": "Question",
        "name": "What kind of support or documentation is available for dox2U’s Tally Plugin?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "dox2U typically provides comprehensive documentation, including installation guides, user manuals, FAQs, and troubleshooting resources. Additionally, support may be available through email, online chat, or a dedicated support portal for any queries or technical assistance needed."
        }
      }]
    }`

    let scriptVideoObject = this.renderer2.createElement('script');
    scriptVideoObject.type = `application/ld+json`;
    scriptVideoObject.text = `{
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": "Introducing dox2U's Tally Add-On",
        "description": "dox2U's Tally Add-On allows you to connect your Tally to dox2U's Cloud! Save time and improve productivity by having your supporting documents right where you need them, in Tally",
        "thumbnailUrl": "https://dox2uprod.blob.core.windows.net/websitedocs/maxresdefault.png",
        "uploadDate": "2024-04-03",
        "duration": "PT1M30S",
        "contentUrl": "https://dox2u.com/e-docs/Tally_Add-On_Overview.mp4"

    }`

    
    this.renderer2.appendChild(this._document.body, script);
    this.renderer2.appendChild(this._document.body, scriptProduct);
    this.renderer2.appendChild(this._document.body, scriptTally);
    this.renderer2.appendChild(this._document.body, scriptFnQ);
    this.renderer2.appendChild(this._document.body, scriptVideoObject);
    AOS.init();
    this.titleService.setTitle(' Simplify Document Management in Tally with Cloud DMS Plugin: Improve Productivity'); /** For Page Title */
    let accordianBodyFirst = document.getElementById("collapseOne");
    accordianBodyFirst?.classList.add('show');
    this.headingContainer1 = document.querySelector('.heading-container') as HTMLElement;
    var tallyUrl = window.location.href.split('/')[3];
    console.log("URL1", tallyUrl);
      if (tallyUrl.includes('#download-and-install')) {
      setTimeout(() => {
      this.scrollToDownloadTallyForm();
    }, 100);
    }

    if(tallyUrl.includes('#partner-with-us')) {
      setTimeout(() => {
        this.scrollToPartnerWithUsForm();
      }, 100);
    }
    return;

    
  }
  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
      this.seoService.createLinkForCanonicalURL();
    }
  /*
    Thie method will scroll to tally form on clicking get started button
  */
  scrollToDownloadTallyForm() {
    const downloadFormSection = document.getElementById('downloadForm');
    if (downloadFormSection) {
      const viewportHeight = window.innerHeight;
        const elementHeight = downloadFormSection.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
        top: downloadFormSection.offsetTop - yOffset,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
      //downloadFormSection.scrollIntoView({ behavior: 'smooth' });
    }
    return;
  }

  scrollToPartnerWithUsForm() {
    const partnerFormSection = document.getElementById('partnerWithUs');
    if (partnerFormSection) {
      const viewportHeight = window.innerHeight;
        const elementHeight = partnerFormSection.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
        top: partnerFormSection.offsetTop - yOffset,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
      //downloadFormSection.scrollIntoView({ behavior: 'smooth' });
    }
    return;
  }

  scrollToGettingStarted() {
    const gettingStartedElement = document.getElementById('gettingStarted');
      this.router.navigate(['/']).then(() => {
        setTimeout(() => {
          const elementAfterNavigation = document.getElementById('gettingStarted');
          if (elementAfterNavigation) {
            const viewportHeight = window.innerHeight;
            const elementHeight = elementAfterNavigation.clientHeight;
            const yOffset = (viewportHeight - elementHeight) / 2;
          const scrollOptions: ScrollToOptions = {
            top: elementAfterNavigation.offsetTop - yOffset + 200,
            behavior: 'smooth',
          };
          window.scrollTo(scrollOptions);
            //elementAfterNavigation.scrollIntoView({ behavior: 'smooth' });
          }
        }, 200);
      });
    
  }
  }

  

